import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import ContactForm from '../components/sections/contact-form'
import HeroTwoPhotosTextLeft from '../components/sections/hero-two-photos-text-left'

import '../styles/pages/page-abovetheline.scss'
import { abovetheline as servicesSchema } from '../utils/schema/services'

const pageAboveTheLine = ({ location }) => {
  const data = useStaticQuery(graphql`{
    ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-abovetheline"}}}) {
      ...GhostPageFields
    }
    hero: file(relativePath: {eq: "hero/hero-abovetheline.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 970, layout: CONSTRAINED)
      }
    }
    hero2: file(relativePath: {eq: "hero/hero-abovetheline2.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 845, layout: CONSTRAINED)
      }
    }
    photo3a: file(relativePath: {eq: "abovetheline/photo3a.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 530, layout: CONSTRAINED)
      }
    }
    photo3b: file(relativePath: {eq: "abovetheline/photo3b.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 286, layout: CONSTRAINED)
      }
    }
    photo3c: file(relativePath: {eq: "abovetheline/photo3c.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 286, layout: CONSTRAINED)
      }
    }
    photo4a: file(relativePath: {eq: "abovetheline/photo4a.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 512, layout: CONSTRAINED)
      }
    }
    photo4bc: file(relativePath: {eq: "abovetheline/photo4bc.jpg"}) {
      childImageSharp {
        gatsbyImageData(width: 683, layout: CONSTRAINED)
      }
    }
    photo5a: file(relativePath: {eq: "abovetheline/photo5a.png"}) {
      childImageSharp {
        gatsbyImageData(width: 570, layout: CONSTRAINED)
      }
    }
    photo5b: file(relativePath: {eq: "abovetheline/photo5b.png"}) {
      childImageSharp {
        gatsbyImageData(width: 570, layout: CONSTRAINED)
      }
    }
    buying: file(relativePath: {eq: "abovetheline/photo6.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    adsReg: file(relativePath: {eq: "abovetheline/photo7.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
  `)
  const image = getImage(data.buying)
  const bgImage = convertToBgImage(image)
	const page = data.ghostPage

	return <>
        <MetaData
            data={data}
            location={location}
            type="website"
        />
        <Helmet>
            <style type="text/css">{`${page.codeinjection_styles}`}</style>
    <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
        </Helmet>
        <Layout>
    <article className="page-above-the-line">
      <HeroTwoPhotosTextLeft
        heroImage={data.hero.childImageSharp.gatsbyImageData}
        heroHeader="ABOVE <br>THE <br>LINE"
        heroText="How well we communicate is determined not by how well we say things, but how well we are understood."
        heroImage2={data.hero2.childImageSharp.gatsbyImageData}
      />
      <section className="above-line__radio container">
        <div className="container medium">
          <div className="row align-items-end">
            <div className="col-md-4 d-none d-md-block">
              <GatsbyImage
                image={data.photo3c.childImageSharp.gatsbyImageData}
                className="radio__side-image"
                alt="" />
              <GatsbyImage
                image={data.photo3b.childImageSharp.gatsbyImageData}
                className="radio__side-image"
                alt="" />
            </div>
            <div className="col-md-6">
              <div className="dec-element--line-vertical">
                <h2 className="radio__title"><span className="radio__title--side">Ραδιοφωνικά</span> Σποτ</h2>
                <GatsbyImage
                  image={data.photo3a.childImageSharp.gatsbyImageData}
                  className="radio__main-image"
                  alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 offset-lg-4">
              <div className="radio__text">
                <p>Το ραδιόφωνο αποτελεί διαχρονικά το αγαπημένο μέσο των Ελλήνων, με φανατικούς ακροατές για όλα τα target group. Εμείς στη Think Plus γνωρίζουμε πως να φτιάχνουμε πιασάρικα σποτ για το ραδιόφωνο που επικοινωνούν αποτελεσματικά το προϊόν σας.</p>
                <p className="h5">Δημιουργούμε ραδιοφωνικά σποτ με έμφαση στο πρωτότυπο concept και την παραγωγή.</p>
                <p>Από το κείμενο, την επιλογή των εκφωνητών και την εκφώνηση, μέχρι την τελική μίξη του ήχου, επιμελούμαστε το ραδιοφωνικό σποτ σε κάθε του λεπτομέρεια, ώστε η διαφήμισή σας να πάρει διαστάσεις viral!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="above-line__outdoor-ad">
        <div className="row no-gutters align-items-end">
          <div className="col-md-4 col-lg-3">
            <GatsbyImage
              image={data.photo4a.childImageSharp.gatsbyImageData}
              alt="Outdoor advertisement" />
          </div>
          <div className="col-md-8 col-lg-5">
            <div className="outdoor-ad__text">
              <h2 className="dec-element--line-vertical">Υπαίθρια Διαφήμιση</h2>
              <p>Οργανώστε μια υπαίθρια διαφημιστική εκστρατεία με τη Think Plus και αυξήστε άμεσα το brand awareness της εταιρείας σας.</p>
              <p className="h5">Αναλαμβάνουμε υπαίθριες διαφημιστικές ενέργειες σε street furniture (στάσεις λεωφορείων, towers, panels), καλύψεις κτιρίων και transit media (τρόλεϊ, τραμ, λεωφορεία).</p>
              <p>Εκμεταλλευτείτε τη μοναδική παρουσία σας στον υπαίθριο χώρο της πόλης και κάντε το προϊόν σας viral!</p>
              <Link to="/contact-us/" className="btn__theme">Ρωτήστε μας περισσότερα</Link>
            </div>
          </div>
          <div className="col-md-8 offset-md-4 col-lg-4 offset-lg-0">
            <GatsbyImage
              image={data.photo4bc.childImageSharp.gatsbyImageData}
              alt="Bus stop advertisement" />
          </div>
        </div>
      </section>
      <section className="above-line__tv-spot">
        <div className="container medium">
          <div className="row">
            <div className="col-md-6">
              <GatsbyImage image={data.photo5a.childImageSharp.gatsbyImageData} alt="TV Spot" />
            </div>
            <div className="col-md-6 align-self-end">
              <div className="tv-spot__text">
                <h2>Τηλεοπτικά Σποτ</h2>
                <p>Η τηλεόραση αποτελεί το πιο δυνατό μέσο για την προώθηση των προϊόντων σας στο ευρύ κοινό. Αναλαμβάνουμε την παραγωγή ενός τηλεοπτικού σποτ από το αρχικό σενάριο μέχρι το on air της διαφήμισης στους τηλεοπτικούς δέκτες. Επιμελούμαστε με προσοχή την προετοιμασία της παραγωγής, την επιλογή συνεργατών, το γύρισμα, το μοντάζ, τη μίξη του ήχου και το post-production.</p>
                <div className="dec-element--line-horizontal"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 order-2 order-md-0 text-align-right">
              <div className="tv-spot__text right">
                <p>Διαθέτουμε πολυετή εμπειρία και τεχνογνωσία στο χώρο της τηλεοπτικής διαφήμισης και φροντίζουμε για τη μέγιστη δυνατή επικοινωνία του προϊόντος σας με το καταναλωτικό κοινό.</p>
                <p className="h5">Ελάτε να σχεδιάσουμε από κοινού το επόμενο τηλεοπτικό σας σποτ!</p>
                <div className="dec-element--line-horizontal"></div>
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-0">
            <GatsbyImage image={data.photo5b.childImageSharp.gatsbyImageData} alt="TV Advertising" />
            </div>
          </div>
        </div>
      </section>
      <BackgroundImage
          Tag="section"
          className="above-line__media"
          {...bgImage}
        >
        <div className="container medium">
          <div className="row">
            <div className="col text-align-center">
              <h2 className="h1 dec-element--line-horizontal">Media Buying</h2>
              <p>Έχετε έτοιμη την καλύτερη διαφήμιση! Πόσο σίγουροι όμως είστε ότι θα φτάσει στο κοινό σας; Εμείς στην Think Plus αναλαμβάνουμε το σχεδιασμό Media Buying του διαφημιστικού σας μηνύματος και εγγυόμαστε άμεσα αποτελέσματα. Οι έμπειροι marketers μας στοχεύουν στο κατάλληλο κοινό και στα κατάλληλα μέσα προώθησης, φροντίζοντας ο χώρος και ο χρόνος προβολής σας να είναι ουσιαστικός. Φροντίζουμε ώστε και το τελευταίο cent από το διαφημιστικό σας budget να μην πάει χαμένο!</p>
              <p className="h5">Επικοινωνήστε μαζί μας για να σχεδιάσουμε το Media Buying των διαφημιστικών σας ενεργειών.</p>
              <Link to="/contact-us/" className="btn__theme">Καλέστε μας σήμερα</Link>
            </div>
          </div>
        </div>
        </BackgroundImage>
        <section className="above-line__ads-reg">
          <div className="container medium">
            <div className="row">
              <div className="col-md-11 dec-box">
                <GatsbyImage
                  image={data.adsReg.childImageSharp.gatsbyImageData}
                  alt="Διαφημιστικές Καταχωρήσεις" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5 offset-md-1 order-2 order-md-0 text-align-right">
                <p>Χαθήκατε στο δρόμο της Above the Line διαφήμισης;<br/>Εμείς στην Think Plus αναλαμβάνουμε την καταχώρηση της διαφήμισής σας και τους στόχους σας, οι έμπειροι marketers μας αναλαμβάνουν την καταχώρηση της διαφήμισής σας σε στοχευμένα περιοδικά και εφημερίδες που θα φέρουν τα καλύτερα αποτελέσματα. Είμαστε δίπλα σας καθ'όλη τη διάρκεια που η διαφημιστική εκστρατεία είναι on air, και προτείνουμε συνεχώς βελτιώσεις για την καλύτερη επικοινωνία με το καταναλωτικό κοινό.</p>
                <p className="h5">Επικοινωνήστε μαζί μας για να ξεκινήσουμε την επόμενη διαφημιστική σας εκστρατεία στις εφημερίδες και τα περιοδικά.</p>
              </div>
              <div className="col-md-5 offset-md-1 order-1 order-md-0 align-self-center">
                <h2 className="h1">Διαφημιστικές <br/>Καταχωρήσεις</h2>
              </div>
            </div>
          </div>
        </section>
      <ContactForm />
    </article>
        </Layout>
    </>;
}

export default pageAboveTheLine
