import React from "react"
import SocialIcons from '../common/SocialIcons'
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/hero-two-photos-text-left.scss'

class HeroTwoPhotosTextLeft extends React.Component {
  render() {
    return (
      <section className="hero-two-photos-text-left dec-section--box-top">
        <div className="container">
          <div className="row">
            <div className="col text-align-right">
              <SocialIcons type={"light"} position={"relative"} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 order-2 order-md-0 offset-md-1">
              <GatsbyImage
                image={this.props.heroImage}
                loading="auto"
                alt=""
                className="hero__image-first" />
            </div>
            <div className="col-md-3 order-1 order-md-0 align-self-center">
              <h1 dangerouslySetInnerHTML={{ __html: this.props.heroHeader }} />
            </div>
          </div>
          <div className="row hero__below">
            <div className="col-md-3 offset-md-1 col-lg-2 offset-lg-2 text-align-right">
              <p dangerouslySetInnerHTML={{ __html: this.props.heroText }} className="h3" />
            </div>
            <div className="col-md-7 offset-md-1">
              <div className="dec-element--line-vertical">
                <GatsbyImage image={this.props.heroImage2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeroTwoPhotosTextLeft